import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { WalletProvider } from "store/wallet";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";
import Staking from "./pages/staking";
import brandTheme from "./assets/theme";
import Referral from "pages/referral";
import CookieConsent, { Cookies } from "react-cookie-consent";
import StakingV2 from "pages/stakingV2";

function App() {
  const ethereum = window.ethereum;
  if (ethereum) {
    ethereum.on("accountsChanged", function (accounts) {
      window.location.reload();
    });
    ethereum.on("chainChanged", function (chainId) {
      window.location.reload();
    });
  }

  return (
    <ThemeProvider theme={brandTheme}>
      <CookieConsent
        buttonStyle={{ color: "#353535", fontSize: "16px" }}
        buttonText="I Agree & Understand"
      >
        <span style={{ fontWeight: "bold" }}>Disclaimer:</span> Without prior
        notice, all information and contents in this publication are subject to
        change, including pricing, which may change in response to supply and
        demand in the market. Cryptocurrency are subject to market risks.
        Depending on the dynamics and variables influencing the securities
        market, such as changes in APR, the quantities of the reward schemes may
        increase or decrease. The past performance of the cryptocurrency assets
        does not guarantee future success of the reward schemes. Any reward paid
        out under one of the reward schemes is neither guaranteed or assured by
        BitNautic; instead, it depends on the existence and sufficiency of
        distributable surplus. Users are urged to carefully read the prospectus
        and seek professional advice from experts regarding any particular
        legal, tax, and financial ramifications of their reward schemes or
        participation in the plan. Any loss suffered by a person or entity as a
        result of a flaw, error, or mistake introduced unintentionally or
        otherwise into the BitNautic website will not be our responsibility.
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
      <WalletProvider>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<StakingV2 />} />
            <Route path="/migrate-btnt-v1/">
              <Route path=":referralAddress" element={<Staking />} />
              <Route path="" element={<Staking />} />
            </Route>
            <Route path="/stake-btnt/">
              <Route path=":referralAddress" element={<StakingV2 />} />
              <Route path="" element={<StakingV2 />} />
            </Route>
            <Route exact path="/refer-and-earn-btnt" element={<Referral />} />
          </Routes>
        </BrowserRouter>
      </WalletProvider>
    </ThemeProvider>
  );
}

export default App;
