import { createTheme } from "@mui/material/styles";

const brandTheme = createTheme({
  palette: {
    primary: {
      main: "#c5cae9",
    },
    secondary: {
      main: "#3f51b5",
    },
    background: {
      default: "#e8eaf6",
    },
    info: {
      main: "#ffffff",
    },
    typography: {
      fontFamily: ["Inter", "-apple-system", "BlinkMacSystemFont"],
    },
    text: {
      primary: "#3f51b5",
      secondary: "#3f51b5",
    },
    // mode: "dark",
  },
});

export default brandTheme;
