import * as React from "react";
import Header from "../components/navbar/header";
import { ThemeProvider } from "@mui/material/styles";
import brandTheme from "../assets/theme";
import CustomFooter from "../components/navbar/footer";
import { Slider } from "../components/stakingSlider/slider";
import Pools from "../components/pools/pool";
// import PoolsItem from "../assets/jsons/pools-mainet.json";
// import LpPoolsV1Item from "../assets/jsons/LppoolsV1-mainet.json";
import PoolsItem from "../assets/jsons/pools-mainet.json";
import LpPoolsV1Item from "../assets/jsons/LppoolsV1-mainet.json";
import UtilityButtons from "components/utilityButtons/utilityButtons";
import LpPools from "components/pools/lpPool";
import LpPoolsV1 from "components/pools/lpPoolV1";
import { Alert } from "@mui/material";

function Staking() {
  return (
    <>
      <ThemeProvider theme={brandTheme}>
        <Header />
        <Slider />
        <Alert severity="warning">
          PHISHING WARNING: please make sure you're visiting
          https://staking.bitnautic.org - check the URL carefully.
        </Alert>
        <Alert severity="warning">
          To migrate your Staked and reward amount just open your pool and click
          on Migrate button then follow the steps(Do not close window between
          steps) once it's done visit the BTNTV2 pools i.e.{" "}
          <a href="https://staking.bitnautic.org/stake-btnt">
            {" "}
            https://staking.bitnautic.org/stake-btnt{" "}
          </a>
          .
        </Alert>
        <Alert severity="warning">
          🚨Attention!🚨: In order to improve the quality of our services, we´re
          currently migrating our token from BitNautic token BTNT to BitNautic
          Token V2 (BTNTV2) For token migration, visit:
          <a
            href="https://migration.bitnautic.org"
            target="_blank"
            rel="noreferrer"
          >
            https://migration.bitnautic.org
          </a>
          .
        </Alert>
        <UtilityButtons />
        <Pools index={0} />

        {LpPoolsV1Item.map((i, index) => {
          return (
            <LpPoolsV1
              index={i.index}
              name={i.pair}
              title={i.title}
              rewardAddress={i.rewardAddress}
              staked={i.staked}
              imageURI={i.imageURI}
              priceTag={i.priceTag}
              quoteAddress={i.quoteAddress}
              quoteCurrency={i.quoteCurrency}
            />
          );
        })}

        {PoolsItem.map((i, index) => {
          return (
            <LpPools
              index={i.index}
              name={i.pair}
              title={i.title}
              rewardAddress={i.rewardAddress}
              staked={i.staked}
              imageURI={i.imageURI}
              priceTag={i.priceTag}
              quoteAddress={i.quoteAddress}
              quoteCurrency={i.quoteCurrency}
            />
          );
        })}

        {/* private pool */}
        {/* {LpPoolsItem.map((i, index) => {
          return (
            <LpPoolsV2
              index={i.index}
              name={i.pair}
              title={i.title}
              rewardAddress={i.rewardAddress}
              staked={i.staked}
              imageURI={i.imageURI}
              priceTag={i.priceTag}
              quoteAddress={i.quoteAddress}
              quoteCurrency={i.quoteCurrency}
            />
          );
        })} */}

        {/* <Pools /> */}
        <CustomFooter />
      </ThemeProvider>
    </>
  );
}

export default Staking;
