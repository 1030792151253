import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import BTNT_REWARD_ABI from "../../assets/abi/BtntLpV1Reward.json";
import ERC20TokenABI from "../../assets/abi/ERC20.json";
import migrationABI from "../../assets/abi/MigrationABI.json";
import { useWallet } from "store/wallet";
import { BTNT_V1_TO_V2_MIGRATION_ADDRESS } from "config/prod";
const Swal = require("sweetalert2");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxWidth: 400,
};

const MigrateLp = ({
  open,
  handleClose,
  rewardBalance,
  lpBalance,
  rewardAddress,
  poolIndex,
  quoteAddress,
  lpTokenAddress,
}) => {
  const { web3, address } = useWallet();
  const [showLp, setShowLp] = React.useState(0);

  const steps = [
    {
      label: "Claim Rewards",
      description: `Reward balance: ${rewardBalance} BTNTV1`,
      buttonTest: "Claim",
    },
    {
      label: "Withdraw LPs",
      //   description: `LP Balance: ${withdrawAmountinWei}`,
      buttonTest: "Withdraw",
    },
    {
      label: "Approval for Migration",
      description: "",
      buttonTest: "Approval",
    },
    {
      label: "Migrate and Staking",
      description: ``,
      buttonTest: "Migrate",
    },
  ];
  const [activeStep, setActiveStep] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const handleNext = async (index) => {
    setLoading(true);
    if (index === 0) {
      const BtntRewardContract = new web3.eth.Contract(
        BTNT_REWARD_ABI,
        rewardAddress
      );
      console.log(poolIndex, lpBalance, quoteAddress, "---data0");
      var transaction = await BtntRewardContract.methods
        .withdraw(poolIndex, "0", quoteAddress)
        .send({
          from: address,
        });

      if (transaction.transactionHash) {
        setLoading(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (index === 1) {
      const BtntRewardContract = new web3.eth.Contract(
        BTNT_REWARD_ABI,
        rewardAddress
      );
      console.log(poolIndex, lpBalance, quoteAddress, "---data");
      var transaction = await BtntRewardContract.methods
        .emergencyWithdraw(poolIndex)
        .send({
          from: address,
        });

      if (transaction.transactionHash) {
        setLoading(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (index === 2) {
      const quoteContract = new web3.eth.Contract(
        ERC20TokenABI,
        lpTokenAddress
      );
      console.log(BTNT_V1_TO_V2_MIGRATION_ADDRESS, lpTokenAddress, "---data1");
      const approve = await quoteContract.methods
        .approve(BTNT_V1_TO_V2_MIGRATION_ADDRESS, "9".repeat(64))
        .send({
          from: address,
        });
      if (approve) {
        setLoading(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      if (!approve.transactionHash) return "Transaction Failed";
    } else if (index === 3) {
      const migrationContract = new web3.eth.Contract(
        migrationABI,
        BTNT_V1_TO_V2_MIGRATION_ADDRESS
      );
      console.log(quoteAddress, index, "---data1");
      poolIndex = parseInt(poolIndex) + 1;
      const migrate = await migrationContract.methods
        .lpTokenMigration(quoteAddress, poolIndex)
        .send({
          from: address,
        });
      if (migrate.transactionHash) {
        setLoading(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div>
      {open ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === 3 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    <Typography>{step.description}</Typography>
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={() => handleNext(index)}
                          sx={{ mt: 1, mr: 1 }}
                          disabled={loading}
                        >
                          {index === steps.length - 1
                            ? "Finish"
                            : step.buttonTest}
                          {loading && (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: green[500],
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          )}
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>
                  Hurray!!! All steps completed - Check your Stake BTNT page for
                  reward
                </Typography>
                <Button href="/stake-btnt" sx={{ mt: 1, mr: 1 }}>
                  Visit Reward BTNTV2
                </Button>
              </Paper>
            )}
          </Box>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

export default MigrateLp;
