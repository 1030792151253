import * as React from "react";
import Header from "../components/navbar/header";
import { ThemeProvider } from "@mui/material/styles";
import brandTheme from "../assets/theme";
import CustomFooter from "../components/navbar/footer";
import { Slider } from "../components/stakingSlider/slider";
import PoolsBtntV2 from "../components/pools/PoolsBtntV2/poolsbtntV2";
// import PoolsItem from "../assets/jsons/pools-mainet.json";
// import LpPoolsV1Item from "../assets/jsons/LppoolsV1-mainet.json";
import PoolsItem from "../assets/jsons/pools-testnet.json";
import LpPoolsV2Item from "../assets/jsons/poolsBtntV2/lpPoolsBtntV2-mainet.json";
import UtilityButtons from "components/utilityButtons/utilityButtons";
import LpPools from "components/pools/lpPool";
import LpPoolsBtntv2 from "components/pools/PoolsBtntV2/lpPoolBtntv2";
import { Alert } from "@mui/material";

function StakingV2() {
  return (
    <>
      <ThemeProvider theme={brandTheme}>
        <Header />
        <Slider />
        <Alert severity="warning">
          PHISHING WARNING: please make sure you're visiting
          https://staking.bitnautic.org - check the URL carefully.
        </Alert>
        <UtilityButtons />
        {/* <PoolsBtntV2 index={0} /> */}

        {LpPoolsV2Item.map((i, index) => {
          return (
            <LpPoolsBtntv2
              index={i.index}
              name={i.pair}
              title={i.title}
              rewardAddress={i.rewardAddress}
              staked={i.staked}
              imageURI={i.imageURI}
              priceTag={i.priceTag}
              quoteAddress={i.quoteAddress}
              quoteCurrency={i.quoteCurrency}
            />
          );
        })}

        {/* {PoolsItem.map((i, index) => {
          return (
            <LpPools
              index={i.index}
              name={i.pair}
              title={i.title}
              rewardAddress={i.rewardAddress}
              staked={i.staked}
              imageURI={i.imageURI}
              priceTag={i.priceTag}
              quoteAddress={i.quoteAddress}
              quoteCurrency={i.quoteCurrency}
            />
          );
        })} */}

        {/* private pool */}
        {/* {LpPoolsItem.map((i, index) => {
          return (
            <LpPoolsV2
              index={i.index}
              name={i.pair}
              title={i.title}
              rewardAddress={i.rewardAddress}
              staked={i.staked}
              imageURI={i.imageURI}
              priceTag={i.priceTag}
              quoteAddress={i.quoteAddress}
              quoteCurrency={i.quoteCurrency}
            />
          );
        })} */}

        {/* <Pools /> */}
        <CustomFooter />
      </ThemeProvider>
    </>
  );
}

export default StakingV2;
