import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useWallet } from "store/wallet";
import { BTNT_REWARD_ADDRESS } from "config/prod";
import BTNT_REWARD_ABI from "../../assets/abi/BtntSailingReward.json";
import ERC20TokenABI from "../../assets/abi/ERC20.json";
import PancakeRouterABI from "../../assets/abi/PancakeRouterABI.json";
import { useParams } from "react-router-dom";
import BigNumber from "bignumber.js";
import { BTNT_TOKEN_CONTRACT_ADDRESSS } from "config/prod";
import { DEFAULT_REFERRAL_ADDRESS } from "config/prod";
import { PANCAKESWAP_ROUTER_ADDRESS } from "config/prod";
import InputAdornment from "@mui/material/InputAdornment";
import { USDT_CONTRACT_ADDRESS } from "config/prod";
import { BNB_CONTRACT_ADDRESS } from "config/prod";
import { BUSD_CONTRACT_ADDRESS } from "config/prod";

import MigrateToken from "./migrateBtntV1";

const Swal = require("sweetalert2");

export default function Pools({ index }) {
  const [stakingAmount, setStakingAmount] = React.useState(0);
  const wallet = useWallet();
  const { isWeb3Enabled, web3, address } = useWallet();
  const [rewardAmount, setRewardAmount] = React.useState(0);
  const [stakedAmount, setStakedAmount] = React.useState(0);
  const [totalStakedAmount, setTotalStakedAmount] = React.useState(0);
  const [quoteCurrencyBal, setQuoteCurrencyBal] = React.useState(0.0);
  const [btntCurrencyBal, setBtntCurrencyBal] = React.useState(0);
  const [frenPrice, setFrenPrice] = React.useState(0);
  const [userLockEndTimeVal, setUserLockEndTimeVal] = React.useState(0);
  const [allowanceValue, setAllowanceValue] = React.useState(0);
  const [isAllowance, setAllowance] = React.useState(false);
  const [pair, setPair] = React.useState("USDT");
  const [imageURI, setImageURI] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [quoteTokenAddress, setQuoteTokenAddress] = React.useState(
    USDT_CONTRACT_ADDRESS
  );
  const [migrationReq, setMigrationReq] = React.useState(false);
  const [exactStakedAmount, setExactStakedAmount] = React.useState("");
  var { referralAddress } = useParams();

  const handleClickMaxValue = async () => {
    setStakingAmount(100);
    if (parseFloat(allowanceValue) < parseFloat(quoteCurrencyBal)) {
      setAllowance(true);
    } else {
      setAllowance(false);
    }
  };

  const handleStakeAmount = async (event) => {
    setStakingAmount(event.target.value);
    if (
      parseFloat(allowanceValue) < parseFloat(event.target.value) &&
      pair !== "BNB"
    ) {
      setAllowance(true);
    } else {
      setAllowance(false);
    }
  };
  const handleMigration = async () => {
    // Migration in 3 steps
    // 1. Withdraw all funds
    // 2. Approve for v2 contract for staking and Migration
    // 3. Migration and Staking Would take place
    // }

    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleStakeToken = async (index) => {
    if (isWeb3Enabled && address) {
      if (
        localStorage.getItem("referralAddress") === undefined ||
        localStorage.getItem("referralAddress") === "" ||
        localStorage.getItem("referralAddress") === null
      ) {
        referralAddress = DEFAULT_REFERRAL_ADDRESS;
      } else {
        referralAddress = localStorage.getItem("referralAddress");
      }
      console.log("referralAddress", referralAddress);
      if (quoteCurrencyBal < stakingAmount) {
        Swal.fire({
          title: `Insufficient ${pair} Amount`,
          icon: "error",
          confirmButtonText: "Okay",
        });
        return;
      }
      if (parseFloat(stakingAmount) > 100) {
        Swal.fire({
          title: `Price Impact too High.`,
          text: `Don't invest more than 100 USDT at once. Invest multiple times!`,
          icon: "warning",
          confirmButtonText: "Okay",
        });
        return;
      }
      if (
        stakingAmount == 0 ||
        stakingAmount === undefined ||
        stakingAmount === ""
      ) {
        Swal.fire({
          title: "Incorrect Amount!",
          text: "Enter the correct amount",
          icon: "error",
          confirmButtonText: "Okay",
        });
        return;
      }
      if (referralAddress === address) {
        Swal.fire({
          title: "Incorrect Referral Address!",
          text: "You have entered your own referral Address!",
          icon: "error",
          confirmButtonText: "Okay",
        });
        return;
      }
      Swal.fire({
        title: "Transaction In Process... Do not close this window.",
        didOpen: () => {
          Swal.showLoading();
        },
      });
      if (isWeb3Enabled && pair !== "BNB") {
        const BtntRewardContract = new web3.eth.Contract(
          BTNT_REWARD_ABI,
          BTNT_REWARD_ADDRESS
        );
        const quoteContract = new web3.eth.Contract(
          ERC20TokenABI,
          quoteTokenAddress
        );
        const purchaseAmount = await web3.utils.toWei(
          stakingAmount.toString(),
          "ether"
        );
        const allowances = await quoteContract.methods
          .allowance(address, BTNT_REWARD_ADDRESS)
          .call();
        if (parseFloat(allowances) < parseFloat(purchaseAmount)) {
          const approve = await quoteContract.methods
            .approve(
              BTNT_REWARD_ADDRESS,
              await quoteContract.methods.balanceOf(address).call()
            )
            .send({
              from: address,
            });
          if (approve) {
            Swal.hideLoading();
            Swal.fire({
              title: "Transaction Successful",
              text: "You have Approval for the transaction.",
              icon: "success",
              confirmButtonText: "Okay",
            });
            setAllowance(false);
          }
          updatePoolValues();
          if (!approve.transactionHash) return "Transaction Failed";
        } else {
          var transaction = await BtntRewardContract.methods
            .swapAndDeposit(
              referralAddress,
              index,
              quoteTokenAddress,
              purchaseAmount
            )
            .send({
              from: address,
              gasPrice: web3.utils.toHex(web3.utils.toWei("10", "gwei")),
            });
          if (transaction) {
            Swal.hideLoading();
            Swal.fire({
              title: "Transaction Successful",
              text: "You have deposited your BTNT tokens and now is part of the farm.",
              icon: "success",
              confirmButtonText: "Okay",
            });
          }
          updatePoolValues();
          return transaction;
        }
      } else if (isWeb3Enabled) {
        const BtntRewardContract = new web3.eth.Contract(
          BTNT_REWARD_ABI,
          BTNT_REWARD_ADDRESS
        );
        const purchaseAmount = await web3.utils.toWei(
          stakingAmount.toString(),
          "ether"
        );

        var transactionBNb = await BtntRewardContract.methods
          .swapBNBtoBTNT(referralAddress, index)
          .send({
            from: address,
            value: purchaseAmount,
            gasPrice: web3.utils.toHex(web3.utils.toWei("10", "gwei")),
          });
        if (transactionBNb) {
          Swal.hideLoading();
          Swal.fire({
            title: "Transaction Successful",
            text: "You have deposited your BTNT tokens and now is part of the farm.",
            icon: "success",
            confirmButtonText: "Okay",
          }).then(() => {
            updatePoolValues();
            window.location.reload();
          });
        }
        return transactionBNb;
      }
    } else {
      const ethereum = window.ethereum;
      if (ethereum === undefined) {
        await wallet.enableWeb3("walletconnect");
      } else {
        await wallet.enableWeb3("ethereum");
      }
    }
  };

  const updatePoolValues = React.useCallback(async () => {
    const BtntContract = new web3.eth.Contract(
      ERC20TokenABI,
      BTNT_TOKEN_CONTRACT_ADDRESSS
    );
    const BtntRewardContract = new web3.eth.Contract(
      BTNT_REWARD_ABI,
      BTNT_REWARD_ADDRESS
    );
    const quoteContract = new web3.eth.Contract(
      ERC20TokenABI,
      quoteTokenAddress
    );
    const pendingAmount = await BtntRewardContract.methods
      .pendingBtnt(index, address)
      .call();
    const purchaseAmount = web3.utils.fromWei(
      pendingAmount.toString(),
      "ether"
    );
    const userInfo = await BtntRewardContract.methods
      .userInfo(index, address)
      .call();
    const stakedBTNT = web3.utils.fromWei(
      userInfo["amount"].toString(),
      "ether"
    );
    setExactStakedAmount(userInfo["amount"].toString());
    const poolInfo = await BtntRewardContract.methods.poolInfo(index).call();
    const depositedBTNT = web3.utils.fromWei(
      poolInfo["deposited"].toString(),
      "ether"
    );
    if (stakedBTNT > 0 || purchaseAmount > 0) {
      setMigrationReq(true);
    }
    var quoteCurrBalVal;
    if (pair === "BNB") {
      quoteCurrBalVal = await web3.eth.getBalance(address);
    } else {
      quoteCurrBalVal = await quoteContract.methods.balanceOf(address).call();
    }
    const quoteCurrBalValinEth = web3.utils.fromWei(
      quoteCurrBalVal.toString(),
      "ether"
    );
    const BtntCurrBalVal = await BtntContract.methods.balanceOf(address).call();
    const btntCurrBalValinEth = web3.utils.fromWei(
      BtntCurrBalVal.toString(),
      "ether"
    );
    const PancakeContract = new web3.eth.Contract(
      PancakeRouterABI,
      PANCAKESWAP_ROUTER_ADDRESS
    );

    const amountsVal = await PancakeContract.methods
      .getAmountsOut(new BigNumber(1e18).toString(), [
        BTNT_TOKEN_CONTRACT_ADDRESSS,
        quoteTokenAddress,
      ])
      .call();
    const usdtPrice = await (
      new BigNumber(amountsVal[1]).multipliedBy(1e-18).toNumber() *
      parseFloat(stakedBTNT)
    ).toFixed(4);
    setFrenPrice(usdtPrice);
    const allowances = await quoteContract.methods
      .allowance(address, BTNT_REWARD_ADDRESS)
      .call();
    const allowancesinEth = web3.utils.fromWei(allowances.toString(), "ether");

    setUserLockEndTimeVal(userInfo["lockEndTime"]);
    setAllowanceValue(parseFloat(allowancesinEth));
    setBtntCurrencyBal(parseFloat(btntCurrBalValinEth).toFixed(2));
    setQuoteCurrencyBal(parseFloat(quoteCurrBalValinEth));
    setTotalStakedAmount(parseFloat(depositedBTNT).toFixed(4));
    setRewardAmount(parseFloat(purchaseAmount).toFixed(8));
    setStakedAmount(parseFloat(stakedBTNT).toFixed(4));
  });

  const handleClaim = async (index) => {
    if (isWeb3Enabled && address) {
      if (rewardAmount != 0) {
        const BtntRewardContract = new web3.eth.Contract(
          BTNT_REWARD_ABI,
          BTNT_REWARD_ADDRESS
        );

        var transaction = await BtntRewardContract.methods
          .withdraw(index, "0")
          .send({
            from: address,
          });
        if (transaction.transactionHash) {
          Swal.fire({
            title: "Transaction Successful",
            icon: "success",
            confirmButtonText: "Okay",
          });
          window.location.reload();
          return;
        }
      } else {
        Swal.fire({
          title: "No Reward.",
          text: "You don't have sufficient reward amount to claim.",
          icon: "error",
          confirmButtonText: "Okay",
        });
        updatePoolValues();
        return;
      }
    }
  };

  const handleWithdraw = async (index) => {
    if (isWeb3Enabled && address) {
      if (
        rewardAmount !== 0 &&
        Math.floor(Date.now() / 1000) >= userLockEndTimeVal
      ) {
        const { value: withdrawAmount } = await Swal.fire({
          title: "Enter BTNT Amount to Withdraw",
          input: "number",
          inputLabel: "Enter BTNT Amount",
          showCancelButton: true,
          inputValidator: (value) => {
            if (value >= stakedAmount || !value) {
              return "You have entered insufficient staked amount!";
            }
          },
        });
        if (withdrawAmount <= stakedAmount) {
          const BtntRewardContract = new web3.eth.Contract(
            BTNT_REWARD_ABI,
            BTNT_REWARD_ADDRESS
          );
          const withdrawAmountinWei = await web3.utils.toWei(
            withdrawAmount.toString(),
            "ether"
          );
          var transaction = await BtntRewardContract.methods
            .withdraw(index, withdrawAmountinWei)
            .send({
              from: address,
              gasPrice: web3.utils.toHex(web3.utils.toWei("10", "gwei")),
            });
          if (transaction.transactionHash) {
            Swal.fire({
              title: "Transaction Successful",
              icon: "success",
              confirmButtonText: "Okay",
            });
            window.location.reload();
            return;
          }
        }
      } else {
        Swal.fire({
          title: "Insuffient or Locked for 2 Weeks",
          text: "You don't have staked any amount or it's locked. But you can claim your reward amount.",
          icon: "error",
          confirmButtonText: "Okay",
        });
        return;
      }
    }
  };

  React.useEffect(() => {
    if (isWeb3Enabled && address) {
      if (referralAddress !== undefined) {
        localStorage.setItem("referralAddress", referralAddress);
      }
      if (pair === "BNB") {
        setQuoteTokenAddress(BNB_CONTRACT_ADDRESS);
        setImageURI("https://i.ibb.co/jMtbbm9/BNB-USDT.png");
      } else if (pair === "BUSD") {
        setQuoteTokenAddress(BUSD_CONTRACT_ADDRESS);
        setImageURI("https://i.ibb.co/Kbfh4Bh/BUSD-BTNT.png");
      } else if (pair === "USDT") {
        setQuoteTokenAddress(USDT_CONTRACT_ADDRESS);
        setImageURI("https://i.ibb.co/SNzKZG7/USDT-BTNT.png");
      }
    }
  }, [address, isWeb3Enabled, pair, referralAddress]);

  React.useEffect(() => {
    if (isWeb3Enabled && address) {
      updatePoolValues();
    }
  }, [
    isWeb3Enabled,
    address,
    quoteTokenAddress,
    updatePoolValues,
    migrationReq,
  ]);
  return (
    <div>
      <Box>
        <Container>
          <MigrateToken
            open={open}
            handleClose={handleClose}
            rewardBalance={rewardAmount}
            lpBalance={exactStakedAmount}
            rewardAddress={BTNT_REWARD_ADDRESS}
            poolIndex={index}
            quoteAddress={BTNT_TOKEN_CONTRACT_ADDRESSS}
            lpTokenAddress={BTNT_TOKEN_CONTRACT_ADDRESSS}
          />
          <Accordion sx={{ mb: 3 }} className="rounded-3">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="container text-center">
                {/* <Stack direction="row" spacing={10}> */}
                <div className="row">
                  <div className="col-lg-2 col-12">
                    <img src={imageURI} alt="" />
                  </div>
                  <div className="col-lg-3 col-6">
                    <Typography className="fw-bolder fs-4">
                      Stake BTNT
                    </Typography>
                    <Typography sx={{ color: "#000" }} className="fs-6">
                      Pay in {pair}, and earn BTNT
                    </Typography>
                  </div>
                  <div className="col-lg-2 col-6">
                    <Typography
                      className="fw-bold text-muted"
                      varient="h4"
                      component="h4"
                    >
                      BTNT STAKED
                    </Typography>
                    <Typography sx={{ color: "#000" }}>
                      {stakedAmount} BTNT
                    </Typography>
                    <Typography sx={{ color: "#7986cb" }}>
                      {frenPrice} {pair}
                    </Typography>
                  </div>
                  <div className="col-lg-3 col-12">
                    <Typography
                      className="fw-bold text-muted"
                      varient="h4"
                      component="h4"
                    >
                      TOTAL STAKED
                    </Typography>
                    <Typography sx={{ color: "#000" }}>
                      {totalStakedAmount} BTNT
                    </Typography>
                  </div>
                  <div className="col-lg-2 col-12">
                    <Typography
                      className="fw-bold text-muted"
                      varient="h4"
                      component="h4"
                    >
                      Status
                    </Typography>
                    <span className="badge bg-danger">Migration Required</span>
                  </div>
                </div>
                {/* </Stack> */}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Paper
                    variant="outlined"
                    className="container align-middle mt-2 p-3 lh-lg border border-secondary rounded-4"
                  >
                    {/* <Typography className="fw-normal">
                      {" "}
                      Total locked:{" "}
                      <span className="text-end">42000 BTNT </span>
                    </Typography> */}
                    <div className="container">
                      <div className="row">
                        <div className="col">Total locked:</div>
                        <div className="col align-self-end text-end text-dark">
                          {4240 + totalStakedAmount}BTNT
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">Avg. lock duration:</div>
                        <div className="col text-end">2 Weeks</div>
                      </div>
                      <div className="row">
                        <div className="col">{pair} Balance:</div>
                        <div className="col text-end ext-end text-dark">
                          {" "}
                          {quoteCurrencyBal.toFixed(2)} {pair}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">BTNT Balance:</div>
                        <div className="col text-end ext-end text-dark">
                          {" "}
                          {btntCurrencyBal} BTNT
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    variant="outlined"
                    className="container align-middle mt-2 p-2 lh-lg border border-secondary rounded-4 text-center"
                  >
                    <p className="fw-bold text-dark">
                      Recent <span className="text-primary"> BTNT </span> Earned{" "}
                    </p>
                    <p className="fs-5 fw-bolder">{rewardAmount} BTNT</p>
                    {migrationReq ? (
                      <Button
                        variant="contained"
                        onClick={() => handleMigration()}
                        style={{
                          background: "#0d2258",
                          color: "white",
                        }}
                      >
                        {isWeb3Enabled
                          ? "Migrate Liquidity To BTNT V2"
                          : "Connect Wallet"}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    variant="outlined"
                    className="container align-middle mt-2 p-2 lh-lg border border-secondary rounded-4 text-center"
                  >
                    <p className="fw-bold text-dark">
                      PAY <span className="text-success"> {pair} </span> STAKE{" "}
                      <span className="text-primary"> BTNT </span>
                    </p>
                    <TextField
                      disabled
                      id="filled-basic"
                      label="Enter Amount"
                      variant="filled"
                      className="pb-2"
                      type="number"
                      onChange={handleStakeAmount}
                      value={stakingAmount}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={handleClickMaxValue}
                          >
                            MAX
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Select
                      onChange={(e) => setPair(e.target.value)}
                      value={pair}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em>Select Token</em>
                      </MenuItem>
                      <MenuItem value={"USDT"}>USDT</MenuItem>
                      <MenuItem value={"BNB"}>BNB</MenuItem>
                      <MenuItem value={"BUSD"}>BUSD</MenuItem>
                    </Select>
                    <br />

                    {migrationReq ? (
                      <Button
                        variant="contained"
                        onClick={() => handleMigration()}
                        style={{
                          background: "#0d2258",
                          color: "white",
                        }}
                      >
                        {isWeb3Enabled
                          ? "Migrate Liquidity To BTNT V2"
                          : "Connect Wallet"}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Container>
      </Box>
    </div>
  );
}
