import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import bitnauticLogo from "../../assets/images/bitnautic-app-logo.png";
import { Button } from "@mui/material";
import { useWallet } from "../../store/wallet";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Header() {
  const wallet = useWallet();
  const { isWeb3Enabled, address } = useWallet();

  const handleConnect = React.useCallback(
    async (api) => {
      try {
        const ethereum = window.ethereum;
        if (ethereum === undefined) {
          await wallet.enableWeb3("walletconnect");
        } else {
          await wallet.enableWeb3(api);
        }
      } catch {}
    },
    [wallet]
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography component="div" sx={{ flexGrow: 1 }}>
            <img src={bitnauticLogo} alt="" width={50} />
          </Typography>

          <Search sx={{ mr: 2, display: { xs: "none", sm: "block" } }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <Button
            variant="contained"
            onClick={() => handleConnect("ethereum")}
            color="secondary"
            disabled={isWeb3Enabled}
          >
            <AccountBalanceWalletIcon sx={{ mr: 1 }} />
            {isWeb3Enabled && address !== undefined
              ? address.substring(0, 5) +
                "..." +
                address.substring(address.length - 5)
              : "  Connect Wallet"}
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
