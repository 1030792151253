import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ShareIcon from "@mui/icons-material/Share";
import Paper from "@mui/material/Paper";

export default function CustomFooter() {
  const [value, setValue] = React.useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ pb: 7 }}>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "primary",
        }}
        elevation={3}
      >
        <BottomNavigation showLabels value={value} onChange={handleChange}>
          <BottomNavigationAction
            href="#"
            label="Recents"
            icon={<RestoreIcon />}
          />
          <BottomNavigationAction
            href="/stake-btnt"
            label="Stake BTNT V2"
            icon={<SystemUpdateAltIcon />}
          />
          <BottomNavigationAction
            href="/migrate-btnt-v1"
            label="Migrate BTNT V1"
            icon={<SystemUpdateAltIcon />}
          />
          <BottomNavigationAction
            href="/refer-and-earn-btnt"
            label="Refer Friends"
            icon={<ShareIcon />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
