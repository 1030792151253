import React from "react";
import Carousel from "react-material-ui-carousel";
import { Box } from "@mui/material";
import slider1 from "../../assets/images/slider-1.png";
import slider2 from "../../assets/images/slider-2.png";
import slider3 from "../../assets/images/slider-3.png";
import slider4 from "../../assets/images/slider-4.png";
import slider5 from "../../assets/images/slider-5.png";

export function Slider(props) {
  var items = [
    {
      Image: slider1,
    },
    {
      Image: slider2,
    },
    {
      Image: slider3,
    },
    {
      Image: slider4,
    },
    {
      Image: slider5,
    },
  ];

  return (
    <Carousel>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  return (
    <Box className="text-center">
      <img
        src={props.item.Image}
        alt=""
        className="img-fluid rounded text-center"
        height="500px"
      />
    </Box>
  );
}
