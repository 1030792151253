// Mainnet Details
module.exports = {
  RPC_URL: "",
  BASE_URL: "https://staking.bitnautic.org",
  // BASE_URL: "http://localhost:3000",
  BTNT_REWARD_ADDRESS: "0x30808d5808B684F89bC6E2148afb08C4C47f7a4d",
  BTNT_LP_REWARD_ADDRESS: "0xeAA65F04793Db7b9C37570413b3e91e2463c5f9B",
  BTNT_LP_V1_REWARD_ADDRESS: "0xBD3F84a5CFe6309A8Bb05aEEAED040a98a268D74",
  BTNT_LP_V2_REWARD_ADDRESS: "0x88252CAd7ED245B99BE005dbaD80Ef6B9abac3Bb",
  BNB_CONTRACT_ADDRESS: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  BUSD_CONTRACT_ADDRESS: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  USDT_CONTRACT_ADDRESS: "0x55d398326f99059fF775485246999027B3197955",
  BTNT_TOKEN_CONTRACT_ADDRESSS: "0xB9c7099D2FBbF82314de08045745DaF951CDDa85",
  DEFAULT_REFERRAL_ADDRESS: "0xeFbdCDa0486c3fe34ABc79DAEAD9d22ef98f0fC1",
  PANCAKESWAP_ROUTER_ADDRESS: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  BTNT_V1_TO_V2_MIGRATION_ADDRESS: "0xD0b956013D462234f23F185520fEb56C404929e1",

  BTNT_V2_TOKEN_CONTRACT_ADDRESSS: "0xCbd787129d6e36225D93E46fA08556cC7F3a2458",

  BTNTV2_FARM_REWARD_ADDRESS: "0xb1cd7FFF6833545DBAc5b83851A663ECB14d690d",
};

// Testnet Details
// module.exports = {
//   RPC_URL: "",
//   // BASE_URL: "https://staking.bitnautic.org",
//   BASE_URL: "http://localhost:3000",
//   BTNT_REWARD_ADDRESS: "0xDCD8805E02bAE7104fE721715788A91C6fF4CbE6",
//   BNB_CONTRACT_ADDRESS: "0xeA63FDa80983d58533e818034A7a18c486CA1310",
//   BUSD_CONTRACT_ADDRESS: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
//   USDT_CONTRACT_ADDRESS: "0xd09b8734dBe94C0FDd4B12E565E274A44a805D0b",
//   BTNT_TOKEN_CONTRACT_ADDRESSS: "0x759176bb5A4Ee1413fDa607531790D05D99c7E9B",
//   DEFAULT_REFERRAL_ADDRESS: "0x21F2880da6b96C07dF99CbA694b2eC01a1cA29a6",
//   PANCAKESWAP_ROUTER_ADDRESS: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
//   BTNT_LP_REWARD_ADDRESS: "0x02A6690b550b65C71f522383B8260b72AA9764BB",
//   BTNT_LP_V2_REWARD_ADDRESS: "0x5A6380C43be307D220D273053D9323232D353bAD",
//   BTNT_LP_V1_REWARD_ADDRESS: "0xc9946Bd342378C8FbF4d739CE9E6cc16266A4FAB",

//   BTNT_V1_TO_V2_MIGRATION_ADDRESS: "0x8ecB9E146A0bc980788E35C1Ae6bBB04544e9e43",

//   BTNT_V2_TOKEN_CONTRACT_ADDRESSS: "0x7E6942fAE32F0aD9B3249824530F1939a3eF5A83",
// };
